const messages = {
    'Medical Services': 'Servicios Médicos',
    'Massage appointments': 'Citas de Masaje',
    upcommingAppointments: 'Póximas Citas',
    patients: 'pacientes',
    noAppointments: 'No hay citas para este día',
    hello: 'Hola',
    massageScreenSubheader: 'Echemos un vistazo a tus pacientes y citas para hoy',
    nonSurgicalScreenSubheader: 'Echemos un vistazo a tus pacientes y citas para hoy',
    currentPatient: 'Paciente Actual',
    seeAllSessions: 'Ver todas las Sesiones',
    sessions: 'Sesiones',
    of: 'de',
    nextSessionOn: 'Próxima Sesión',
    serviceDuration: 'Duración de Servicio',
    pause: 'Pause',
    begin: 'Empezar',
    finishSession: '¡Listo!',
    evaluate: 'Evaluar',
    lastVisit: 'Última visita',
    notCheckedInNotification: 'Este paciente no se ha dado de alta',
    notCheckedInNotificationHelpText:
        'Sólo puede empezar una sesión luego que el paciente se de alta en el frontdesk',
    checkInToStartSession: 'Falta check in para comenzar',
    seeAllProcedures: 'Ver Procedimientos',
    noPreviousSession: 'Sin sesión anterior',
    allProceduresOfPatient: 'Todos los procedimientos del paciente',
    preOpEvaluation: 'Pre-Op Evaluación',
    chiefComplaints: 'Principales Quejas',
    pmhx: 'P.M.HX',
    heent: 'Heent',
    smooking: 'fumador',
    cv: 'Cv',
    alcohol: 'Alcohol',
    adb: 'Adb',
    drugs: 'Drogas',
    respiratory: 'Respiratorio',
    other: 'Otros',
    medication: 'Medicación',
    operation: 'Operación',
    allergies: 'Alergias',
    preoptx: 'Pre Op Tx',
    asaStatus: 'ASA Status',
    dateSurgery: 'Fecha de Cirugía',
    remarks: 'Observaciones',
    bloodPressure: 'Presión',
    pulse: 'Pulso',
    gestations: 'Gestaciones',
    childBirths: 'Nacimientos',
    aditionalRequirements: 'Requerimientos Adicionales',
    labs: 'Labs',
    xRay: 'XRay',
    eeg: 'ECG',
    ultrasound: 'Ultrasound',
    mammography: 'Mammography',
    cancelSurgeryCauses: 'Causa de la cancelación de la cirugía',
    cancelSurgeryDescription: 'Seleccione la cause expecifica de la cancelación de la cirugía',
    PositiveTest: 'Examen Positivo',
    MedicalCondition: 'Condición Médica',
    generalPatientDataHelpText: 'Información general sobre el paciente',
    pickUpPersonData: 'Datos de la persona que lo recogera',
    pickUpPersonDataHelpText: 'Introduzca los datos de la persona que lo recogerá',
    completeProceduresDataHelpText: 'Seleccione los procedimientos que han sido completados',
    surgeryTeam: 'Equipo Quirúrgico',
    completedProcedures: 'Procedimientos Completados',
    checkOut: 'Check Out',
    errorTestMissing: 'Faltan los resultados de algunos exámenes',
    notPreviousSurgery: 'No existe una cirugía previa',
    appointmentsOf: 'Citas de',
    totalSessionsCompletedToday: 'Total de sesiones completadas hoy',
    remainingAppointmentsToday: 'citas restantes para hoy',
    thisMonth: 'Este mes',
    sessionsCompleted: 'Sesiones Completadas',
    thisSessionWasCompleted: 'Esta sesión fue completada',
    emergencyContactName: 'Nombre del Contacto de Emergencia',
    emergencyContactSurname: 'Apellido del Contacto de Emergencia',
    emergencyContactPhone: 'Teléfono del Contacto de Emergencia',
    serviceStartedInNotification: 'Existe un servicio iniciado',
    serviceStartedInNotificationHelpText:
        'No puedes seleccionar otra sesión mientras exista otra iniciada',
    startedOn: 'Comenzado en',
    completeMassageSession: '¿Terminar esta sesión de masaje?',
    completeMassageSessionHelpText: 'La cita será marcada como completada',
    completedOn: 'Completada el',
    updateMedicalRecord: 'Actualizar Expediente Record',
    createNewMedicalRecord: 'Crear Nueva Historia Clínica',
    cancelSession: '¿Cancelar la sesión actual?',
    cancelSessionHelpText: 'Esta acción reinicará el tiempo de servicio y el tiempo de inicio',
    evaluateAppointment: 'Evaluar cita',
    uploadEvaluation: 'Cargar evaluación',
    thisAppointmentWasCancelled: 'Esta cita fue cancelada',
    draft: 'Borrador',
    classification: 'Clasificación',
    dispatchAccessories: 'Despachar accesorios',
    dispatchAccessoriesHelpText:
        'Opcionalmente puedes despachar los accesorios del paciente desde aquí',
    dispatchNow: 'Despachar ahora',
    dispatchSuccessful: 'Despacho satisfactorio',
    ProviderAssistants: 'Asistentes del Proveedor',
    thisLastWeeks: 'Ultimas {field} Semanas',
    confirmationRecorded:
        'Al continuar, confirmas que has verificado, según tus mejores habilidades, que los procedimientos y las cantidades ingresadas son precisos y están actualizados. Tu confirmación será registrada.',
    doubleCheck: 'Dejame verificar nuevamente...',
    doubleCheckThis: 'Double check this.',
    evaluations: 'Evaluaciónes',
    cancelCauseFeeWarning:
        'Causa seleccionada {cause} cobrarà al pacient una tarifa de $500. Continuar?',
};

export default messages;
